import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLocalStorage from 'react-use-localstorage';
import { addDays, subDays } from 'date-fns';

// MUI
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useGridApiRef, GridRowModes } from '@mui/x-data-grid-pro';
import { Dialog, DialogContent, TextField, Tooltip } from '@mui/material';

// Portal
import { useAuthContext } from '../useAuthContext';
import TrackersAPI from '../api/TrackersAPI.js';
import UserAPI from '../api/UserAPI.js';
import ClientAPI from '../api/ClientAPI.js';
import MultiAutoComplete from './MultiAutoComplete.js';
import { TDGPrefsContext, useProvideTDGPrefsContext } from './TDGPrefsContext';
import { TrackerDataGrid, determineInitialHideShowState } from './TrackerDataGrid.js';
import CreateMinibidDialog from './dialogs/CreateMinibidDialog.js';

import NotesEditDialog from './dialogs/NotesEditDialog.js';
import { PromptUpdateOrSaveNewViewDialog } from './customcomponents/PromptUpdateOrSaveNewViewDialog.js';
import { PromptUpdateOrSaveNewSearchDialog } from './customcomponents/PromptUpdateOrSaveNewSearchDialog.js';
import { Form470AppsBulkEdit } from './dialogs/Form470AppsBulkEdit.js';

// Columns
import Form470ApplicationsColumnsDefinitionCreator from './datagridcolumns/Form470ApplicationsColumnsDefinitionCreator.js';

export default function Form470TrackerApplications(props) {
    const appliesTo = 'Form470 Tracker';
    const trackersAPI = new TrackersAPI();
    const userAPI = new UserAPI();
    const clientAPI = new ClientAPI(); // For the "Create Mini-bid" dialog UI. For searching the "Client List".
    const [searchParameters, setSearchParameters] = React.useState(null);
    const [currentUserID, setCurrentUserID] = React.useState(null);
    const auth = useAuthContext();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const benFromURL = parseInt(urlSearchParams.get('ben'));
    const fundingYearFromURL = parseInt(urlSearchParams.get('fundingYear'));
    const usacAppStatusFromURL = parseInt(urlSearchParams.get('usacAppStatus'));
    const applicationTypeFromURL = String(urlSearchParams.get('applicationType'));

    React.useEffect(() => {
        const getCurrentUserID = async (cognitoID) => {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetDatabaseIDForUser';
            const queryStringParameters = { queryStringParameters: { cognito_id: cognitoID } };

            const idResponse = await API.get(apiName, path, queryStringParameters);
            setCurrentUserID(idResponse);
        };

        getCurrentUserID(auth.cognitoID);
    }, []);

    const handleDetailsButtonClick = (form470AppNum, form470AppIncrement) => {
        navigate('details/' + form470AppNum + '/' + form470AppIncrement);
    };

    const handleEditButtonClick = (form470AppNum, form470AppIncrement) => {
        navigate('edit/' + form470AppNum + '/' + form470AppIncrement);
    };

    const clearQueryStrings = () => {
        navigate(location.pathname, { replace: true });
    };

    // handleFieldsInitialized puts a value into searchParameters which causes the table to query and load data on first page load.
    return (
        <>
            <Form470TrackerAppsSearchBox
                appliesTo={appliesTo}
                benFromURL={benFromURL}
                clearQueryStrings={clearQueryStrings}
                currentUserID={currentUserID}
                defaultBen={params.ben}
                fundingYearFromURL={fundingYearFromURL}
                usacAppStatusFromURL={usacAppStatusFromURL}
                applicationTypeFromURL={applicationTypeFromURL}
                handleFieldsInitialized={setSearchParameters}
                handleSearchButtonClick={setSearchParameters}
                trackersAPI={trackersAPI}
                userAPI={userAPI}
            />
            <LastForm470UsacDataRetrievalDatetimes trackersAPI={trackersAPI} />
            <Form470TrackerAppsTable
                appliesTo={appliesTo}
                benFromURL={benFromURL}
                clientAPI={clientAPI}
                currentUserID={currentUserID}
                fundingYearFromURL={fundingYearFromURL}
                usacAppStatusFromURL={usacAppStatusFromURL}
                applicationTypeFromURL={applicationTypeFromURL}
                handleDetailsButtonClick={handleDetailsButtonClick}
                handleEditButtonClick={handleEditButtonClick}
                searchParameters={searchParameters}
                trackersAPI={trackersAPI}
                userAPI={userAPI}
            />
        </>
    );
}

function Form470TrackerAppsTable({
    appliesTo,
    benFromURL,
    clientAPI,
    currentUserID,
    fundingYearFromURL,
    usacAppStatusFromURL,
    applicationTypeFromURL,
    handleDetailsButtonClick,
    handleEditButtonClick,
    userAPI,
    searchParameters,
    trackersAPI,
}) {
    const CACHE_INCREMENT = 1;
    const F470_CACHE_NAME = 'ced-f470tapplications-cache-' + CACHE_INCREMENT;
    const LS_INCREMENT = 1;
    const SS_INCREMENT = 1;
    const defaultFilterPrefs = '{"items":[]}';
    const defaultSortPrefs = '[]';
    const defaultViewPrefs = '{}';
    const defaultSavedViewNamePrefs = '';
    const defaultCurrentSavedViewWidthPrefs = '{}';
    const defaultOriginalSavedViewWidthPrefs = '{}';
    const defaultCurrentSavedViewOrderPrefs = '[]';
    const defaultOriginalSavedViewOrderPrefs = '[]';
    const defaultHiddenColumns =
        '{"application_fundingYear":false,"application_ben":false,"application_billedentityname":false,"qa_review_status_id":false,"qa_submitter_user_id":false,"qa_reviewer_user_id":false,"qa_date_submitted":false,"qa_date_needed":false,"qa_date_assigned":false,"qa_date_completed":false,"qa_bid_eval_status_id":false,"qa_bid_eval_submitter_user_id":false,"qa_bid_eval_reviewer_user_id":false,"qa_bid_eval_date_submitted":false,"qa_bid_eval_date_needed":false,"qa_bid_eval_date_completed":false,"qa_notes":false,"qa_issues":false}';
    const defaultPinnedColumns = '{"left":["actions"],"right":[]}';

    // ################################### TDGPrefs CONTEXT #############################################
    const prefs = useProvideTDGPrefsContext(
        LS_INCREMENT, // localStorageIncrement,
        SS_INCREMENT, // sessionStorageIncrement,
        'cedF470TApplications', // localStoragePrefix,
        'cedF470TApplications', // sessionStoragePrefix,
        defaultFilterPrefs, // defaultFilterPrefs,
        defaultSortPrefs, // defaultSortPrefs,
        defaultViewPrefs,
        defaultSavedViewNamePrefs,
        defaultCurrentSavedViewWidthPrefs,
        defaultOriginalSavedViewWidthPrefs,
        defaultCurrentSavedViewOrderPrefs,
        defaultOriginalSavedViewOrderPrefs,
        defaultHiddenColumns, // defaultHiddenColumns,
        defaultPinnedColumns // defaultPinnedColumns
    );

    // ################################### User data for NotesEditDialog and Bulk Editing #############################################
    const [userData, setUserData] = React.useState(null);

    // ################################### Bulk Editing #############################################
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [bulkEditButtonClicked, setBulkEditButtonClicked] = React.useState(false);
    const [pruUpdatedRow, setPruUpdatedRow] = React.useState({}); // Needed to prevent BE issues after inline editing

    // ################################### NOTES STATE #############################################
    const [openNotesEditDialog, setOpenNotesEditDialog] = React.useState(false);
    const [editableNotes, setEditableNotes] = React.useState('');
    const [notesEditRowId, setNotesEditRowId] = React.useState(null); // Used in saveEditedNotes function.
    const [notesFieldName, setNotesFieldName] = React.useState('');
    const [notesEditDialogTitle, setNotesEditDialogTitle] = React.useState('');
    const [notesEditDialogLabel, setNotesEditDialogLabel] = React.useState('');

    // ################################### VIEW STATE #############################################
    const [resetWatcher, setResetWatcher] = React.useState(0);
    const [currentSavedViews, setCurrentSavedViews] = React.useState([]);
    const [editingSavedViewName, setEditingSavedViewName] = React.useState('');
    const [currentColumnWidths, setCurrentColumnWidths] = React.useState({});
    const [currentColumnOrder, setCurrentColumnOrder] = React.useState([]);
    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');
    const [savedViewObject, setSavedViewObject] = React.useState({});

    // ################################### IS ANY OF FITER #############################################
    const [applicantMSFilterOptions, setApplicantMSFilterOptions] = React.useState([]);
    const [applicantCohortMSFilterOptions, setApplicantCohortMSFilterOptions] = React.useState([]);
    const [form470AFYStatusesMSFilterOptions, setForm470AFYStatusesMSFilterOptions] = React.useState([]);
    const [serviceTypesMSFilterOptions, setServiceTypesMSFilterOptions] = React.useState([]);
    const [lastUpdatedByMSFilterOptions, setLastUpdatedByMSFilterOptions] = React.useState([]);
    const [billedEntityNameMSFilterOptions, setBilledEntityNameMSFilterOptions] = React.useState([]);
    const [usacAppStatusMSFilterOptions, setUsacAppStatusMSFilterOptions] = React.useState([]);

    const apiRef = useGridApiRef();
    const [trackerData, setTrackerData] = React.useState([]);
    const [dataIsLoading, setDataIsLoading] = React.useState(false);
    const [usedCacheOnLoad, setUsedCacheOnLoad] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState({});

    // ----- "Create Mini-bid" modal dialog -----
    const [openCMDialog, setOpenCMDialog] = React.useState(false);
    const [cmResult, setCMResult] = React.useState(null);

    const handleCreateMinibidButtonClick = () => {
        setCMResult(null); // reset state
        setOpenCMDialog(true);
    };

    // ----- Inline-edit dropdowns options -----
    const [haveEditDropdownOptions, setHaveEditDropdownOptions] = React.useState(false);
    const [applicationOwnerEditOptions, setApplicationOwnerEditOptions] = React.useState([]);
    const [taskOwnerEditOptions, setTaskOwnerEditOptions] = React.useState([]);
    const [ercAppStatusEditOptions, setERCAppStatusEditOptions] = React.useState([]);
    const [qaReviewStatusEditOptions, setQAReviewStatusEditOptions] = React.useState([]);
    const [qaReviewerEditOptions, setQAReviewerEditOptions] = React.useState([]);
    const [qaSubmitterEditOptions, setQASubmitterEditOptions] = React.useState([]);

    const [qaBidEvalStatusEditOptions, setQABidEvalStatusEditOptions] = React.useState([]);
    const [qaBidEvalReviewerEditOptions, setQABidEvalReviewerEditOptions] = React.useState([]);
    const [qaBidEvalSubmitterEditOptions, setQABidEvalSubmitterEditOptions] = React.useState([]);

    const [qaIssuesEditOptions, setQAIssuesEditOptions] = React.useState([]);
    const [qaDarkFiberEditOptions, setQADarkFiberEditOptions] = React.useState([]);

    const [historyModalOpen, setHistoryModalOpen] = React.useState(false);
    const [activeHistoryForm, setActiveHistoryForm] = React.useState(null);

    // Gets all of the saved views (should be called on page load within useEffect)
    const getSavedViews = async (ap) => {
        const viewResults = await userAPI.GetSavedViews(ap);
        const parsedViewResults = JSON.parse(viewResults);
        // console.log('Form470TrackerAppsTable: getSavedViewsFN: parsedViewResults = ', parsedViewResults);

        // Defines the base layout for all retrieved views
        const structuredViews = parsedViewResults.map((ea_row) => ({
            view_id: ea_row.id,
            view_name: ea_row.view_name,
            parameters: ea_row,
        }));
        structuredViews.sort((a, b) => a.view_name.localeCompare(b.view_name));
        //console.log('Form470TrackerAppsTable: getSavedViewsFN: structuredViews = ', structuredViews);

        setCurrentSavedViews(structuredViews);
    };

    React.useEffect(() => {
        const checkCacheForPreviousSearch = async () => {
            let APICache = await caches.open(F470_CACHE_NAME);
            const response = await APICache.match('/Form470Tracker/app/search');

            if (response !== undefined) {
                const cachedData = await response.json();
                // let rowData = updateRowsWithEditedEntry(cachedData, updatedRow);
                // setTrackerData(rowData);
                setTrackerData(cachedData);
                setUsedCacheOnLoad(true);
            }
        };

        const getOptionsForFiltering = async () => {
            try {
                // Get dropdown and multi-select options
                // Returns a data-populated object of drop-down options, then sets the corresponding states with it
                let options = await trackersAPI.GetForm470TrackerMainReportFilterOptions();
                // console.log('[getOptionsForFiltering][options] =', options);
                setApplicantMSFilterOptions(options.applicants); // (client_name, client_id).
                setApplicantCohortMSFilterOptions(options.applicantCohorts);
                setForm470AFYStatusesMSFilterOptions(options.form470AFYStatuses);
                setServiceTypesMSFilterOptions(options.serviceTypes);
                setLastUpdatedByMSFilterOptions(options.employees);
                setBilledEntityNameMSFilterOptions(options.billedEntityNames);
                setUsacAppStatusMSFilterOptions(options.usacAppStatuses);
                // setNicknamesMSFilterOptions(options.nicknames);
            } catch (error) {
                console.log('Error getting filter options: ' + error);
                toast.error(error);
            }
        };

        const fetchInlineEditOptions = async () => {
            try {
                let result = await trackersAPI.GetForm470TrackerMainReportEditOptions();
                result.qaDarkFiber[0]['value'] = '<blank>';

                setApplicationOwnerEditOptions(result.applicationOwners);
                setTaskOwnerEditOptions(result.taskOwners);
                setERCAppStatusEditOptions(result.ercAppStatuses);
                setQAReviewStatusEditOptions(result.qaReviewStatuses);
                setQAReviewerEditOptions(result.qaReviewers);
                setQASubmitterEditOptions(result.qaSubmitters);
                setQABidEvalStatusEditOptions(result.qaBidEvalStatuses);
                setQABidEvalReviewerEditOptions(result.qaBidEvalReviewers);
                setQABidEvalSubmitterEditOptions(result.qaBidEvalSubmitters);
                setQAIssuesEditOptions(result.qaIssues);
                setQADarkFiberEditOptions(result.qaDarkFiber);
                setHaveEditDropdownOptions(true);
                // console.log('[fetchInlineEditOptions] inline-editing options = ', result);
            } catch (error) {
                console.error('fetchInlineEditOptions error: ', error);
                toast.error(error);
            }
        };

        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            setUserData(parsedObject);
        };

        // if (!benFromURL && !fundingYearFromURL && !usacAppStatusFromURL && !applicationTypeFromURL) {
        //     checkCacheForPreviousSearch();
        // }
        if (
            !benFromURL &&
            !fundingYearFromURL &&
            !usacAppStatusFromURL &&
            (!applicationTypeFromURL || applicationTypeFromURL == 'null')
        ) {
            checkCacheForPreviousSearch();
        }
        // if (!benFromURL && !fundingYearFromURL && !applicationTypeFromURL) {
        //     checkCacheForPreviousSearch();
        // }
        // if (!benFromURL && !fundingYearFromURL) {
        //     checkCacheForPreviousSearch();
        // }
        fetchInlineEditOptions();
        getOptionsForFiltering();
        getUserData();
        getSavedViews(appliesTo);
    }, []);

    //----- Generate the report (whenever searchParameters changes) -----
    React.useEffect(() => {
        const searchForm470Applications = async (searchParameters) => {
            try {
                if (!usedCacheOnLoad) {
                    setDataIsLoading(true);
                }

                // Contains LS/SS data or default data
                const searchParametersForAPI = {
                    funding_years: searchParameters.fundingYears,
                    category: searchParameters.categoryOfService,
                    ben: searchParameters.ben,
                    application_number: searchParameters.applicationNumber,
                    nickname: searchParameters.nickname,
                    primary_contacts: searchParameters.primaryContacts,
                    secondary_contacts: searchParameters.secondaryContacts,
                    application_owners: searchParameters.applicationOwners,
                    task_owners: searchParameters.taskOwners,
                    teams: searchParameters.teams,
                    applicant: searchParameters.applicant,
                    states: searchParameters.states,
                    applicant_cohorts: searchParameters.applicantCohorts,
                    service_types: searchParameters.serviceTypes,
                    is_a_client: searchParameters.isAClient,
                    services: searchParameters.services,
                    application_type: searchParameters.applicationType,
                    usac_app_statuses: searchParameters.usacAppStatuses,
                    erc_app_statuses: searchParameters.ercAppStatuses,
                    qa_statuses: searchParameters.qaStatuses,
                    qa_reviewers: searchParameters.qaReviewers,
                    qa_bid_eval_statuses: searchParameters.qaBidEvalStatuses,
                    qa_bid_eval_reviewers: searchParameters.qaBidEvalReviewers,
                    qa_issues: searchParameters.qaIssues,
                    // deadline_field_name: searchParameters?.deadlineFieldName?.value ?? null,
                    // deadline_field_operator: searchParameters?.deadlineFieldOperator?.value ?? null,
                    // deadline_field_days: searchParameters?.deadlineFieldDays ?? null,
                    // calculated_deadline_date: searchParameters.calculatedDeadlineDate
                };

                // const deadlineFieldName = searchParameters?.deadlineFieldName?.value ?? null;
                // const deadlineFieldOperator = searchParameters?.deadlineFieldOperator?.value ?? null;
                // const deadlineFieldDays = searchParameters?.deadlineFieldDays ?? null;
                // // console.log(deadlineFieldName, deadlineFieldOperator, deadlineFieldDays);

                // if (deadlineFieldName && deadlineFieldOperator && deadlineFieldDays) {
                //     let targetDate = 0;
                //     const currentDate = new Date();
                //     searchParametersForAPI.current_date = currentDate.toISOString().split('T')[0];

                //     if (deadlineFieldOperator == 'isBeforeXDays') {
                //         targetDate = subDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }

                //     if (deadlineFieldOperator == 'isAfterXDays') {
                //         targetDate = addDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }
                // }
                console.log(
                    '[Form470TrackerAppsTable, React.useEffect, searchForm470Applications]  searchParametersForAPI = ',
                    searchParametersForAPI
                );

                let trackerSearchResult = await trackersAPI.Form470TrackerMainReportSearch(searchParametersForAPI);
                console.log(
                    '[Form470TrackerAppsTable, React.useEffect, searchForm470Applications]  trackerSearchResult = ',
                    trackerSearchResult
                );
                setTrackerData(trackerSearchResult);
                // if (benFromURL && fundingYearFromURL) {
                //     setAppCount(trackerSearchResult.length);
                // };

                if (trackerSearchResult !== false && trackerSearchResult.body !== '[]') {
                    let APICache = await caches.open(F470_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    APICache.put(
                        '/Form470Tracker/app/search',
                        new Response(JSON.stringify(trackerSearchResult), headersOptions)
                    );
                } else {
                    //console.log('trackerSearchResult.body IS FALSE/UNDEFINED');
                }

                setTrackerData(trackerSearchResult);
                setUsedCacheOnLoad(false);
                setDataIsLoading(false);
            } catch (error) {
                console.log(error);
                toast.error(error);
            }
        };

        if (searchParameters) {
            //console.log('Form470TrackerAppsTable: searchForm470ApplicationsFN: executing search...');
            if (!usedCacheOnLoad) {
                setTrackerData([]); // Setting data to a empty array displays the loading spinner while we query for new data.
            }
            searchForm470Applications(searchParameters);
        }
    }, [searchParameters]);

    // ################################### SAVED VIEWS #############################################
    const endsWithView = (viewName) => {
        const lowercaseName = viewName.trim().toLowerCase();
        return lowercaseName.endsWith('view') || lowercaseName.split(-4).pop().endsWith('view');
    };

    const viewNameExists = (viewName) => {
        return currentSavedViews.some((ea_view) => ea_view.view_name === viewName);
    };

    const handleSaveView = async (vp_obj) => {
        const newOrUpdatedViewName = prefs.viewNamePrefs;
        setSavedViewObject(vp_obj);

        if (newOrUpdatedViewName !== '' && newOrUpdatedViewName !== null) {
            // Search for duplicate/determine if the user has already selected a view
            const duplicateViewIndex = currentSavedViews.findIndex(
                (ea_view) => ea_view.view_name === newOrUpdatedViewName
            );

            // If a duplicate was found/the user has already selected a view
            if (duplicateViewIndex !== -1) {
                openDialog(newOrUpdatedViewName); // opens the promptUpdateOrSaveNewView
            }
        } else {
            openDialog2();
            promptSaveNewView();
        }

        getSavedViews(appliesTo);
        prefs.setViewNamePrefs(newOrUpdatedViewName);
    };

    const handleOnlyUpdateView = async () => {
        let message = 'update';
        let newOrUpdatedViewName = prefs.viewNamePrefs;
        let tempCurrentSavedViews = [...currentSavedViews];

        const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === newOrUpdatedViewName);

        // Finds the matching view object
        const matchingSavedView = currentSavedViews[duplicateViewIndex];

        // Building the updated view object (state updates too slowly to add viewName to CSPO)
        const replacementView = {
            view_id: matchingSavedView.parameters.id,
            view_name: newOrUpdatedViewName,
            parameters: savedViewObject,
        };
        replacementView['parameters']['viewName'] = newOrUpdatedViewName;

        // Update state
        tempCurrentSavedViews[duplicateViewIndex] = replacementView;
        const response = await userAPI.SaveView(
            currentUserID,
            message,
            appliesTo,
            tempCurrentSavedViews.at(duplicateViewIndex)
        );

        if (response) {
            setLanding(false);
            setShowTextField1(false);
            setIsDialogOpen1(false);

            let successMessage = endsWithView(newOrUpdatedViewName)
                ? `Successfully updated the ${newOrUpdatedViewName}`
                : `Successfully updated the ${newOrUpdatedViewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithView(newOrUpdatedViewName)
                ? `Failed to update the ${newOrUpdatedViewName}`
                : `Failed to update the ${newOrUpdatedViewName} saved view`;

            toast.error(failedMessage);
        }
        getSavedViews(appliesTo);
        setLanding(true);
    };

    const handleOnlySaveAsNewView1 = () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewView2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved view name cannot be empty!');
            return;
        }

        const message = 'new';
        const viewName = textFieldValue.trim();
        let tempCurrentSavedViews = [...currentSavedViews];

        if (viewNameExists(viewName)) {
            toast.error(
                `A saved view with the name ${viewName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const newView = {
            view_name: viewName,
            parameters: savedViewObject,
        };
        newView['parameters']['viewName'] = viewName;
        tempCurrentSavedViews.push(newView);

        const response = await userAPI.SaveView(currentUserID, message, appliesTo, tempCurrentSavedViews.at(-1));
        if (response) {
            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            let successMessage = endsWithView(viewName)
                ? `Successfully created the ${viewName}`
                : `Successfully created the ${viewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithView(viewName)
                ? `Failed to create the ${viewName}`
                : `Failed to create the ${viewName} saved view`;

            toast.error(failedMessage);
        }
        setLanding(true);
        getSavedViews(appliesTo);
        prefs.setViewNamePrefs(viewName);
    };

    // Called when the user clicks the edit icon
    const handleEditSavedViewName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/view_name already exists for the other saved views
            if (currentSavedViews.some((ea_view) => ea_view.view_name === new_name)) {
                alert('A saved view with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the view we want to edit
            const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === old_name);

            // If a duplicate was found, let's update it
            if (duplicateViewIndex !== -1) {
                // Returns an array of objects with the previous saved view + the edited view
                const updatedViews = currentSavedViews.map((ea_view, index) => {
                    if (index === duplicateViewIndex) {
                        return {
                            ...ea_view,
                            view_name: new_name,
                            parameters: {
                                ...ea_view.parameters,
                                view_name: new_name,
                                view_filters: {
                                    ...ea_view.parameters.view_filters,
                                    viewName: new_name,
                                },
                            },
                        };
                    }
                    return ea_view;
                });
                // console.log(
                //     'Form470TrackerAppsTable: editSavedViewNameFN: modified/edited object = ',
                //     updatedViews.at(duplicateViewIndex)
                // );

                // Process the edited view
                const view_response = await userAPI.SaveView(
                    currentUserID,
                    message,
                    appliesTo,
                    updatedViews.at(duplicateViewIndex)
                );
                // console.log('Form470TrackerAppsTable: editSavedViewNameFN: view_response = ', view_response);

                // Update the state
                if (old_name === prefs.viewNamePrefs) {
                    prefs.setViewNamePrefs(new_name);
                    // setSelectedViewName(new_name);
                }
                getSavedViews(appliesTo);

                if (view_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedView = async (view_name, view_id, applies_to, user_id) => {
        let confirmationMessage = endsWithView(view_name)
            ? `Are you sure you want to delete the ${view_name}?`
            : `Are you sure you want to delete the ${view_name} saved view?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedView(view_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithView(view_name)
                    ? `Successfully deleted the ${view_name}`
                    : `Successfully deleted the ${view_name} saved view`;

                toast.success(successMessage, {
                    autoClose: 3000,
                });

                prefs.resetViewNamePrefs();
                getSavedViews(appliesTo);
            } else {
                const errorMessage = endsWithView(view_name)
                    ? `Failed to delete the ${view_name}`
                    : `Failed to delete the ${view_name} saved view`;

                toast.error(errorMessage);
            }
            // console.log('Form470TrackerAppsTable: deleteSavedViewFN: delete_response = ', delete_response);
        }
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewView2();
        }
    };

    // Asks if the user wants to update the current view or save it as a new view, controls dialog
    const openDialog = (newOrUpdatedViewName) => {
        let confirmationMessage = endsWithView(newOrUpdatedViewName)
            ? `Do you want to update the ${newOrUpdatedViewName} or save as a new view?`
            : `Do you want to update the ${newOrUpdatedViewName} saved view or save as a new view?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewView = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved view: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='View Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewView2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    // ################################### END SAVED VIEWS #############################################

    // ################################### Custom Notes Start (NotesEditDialog) #############################################

    const usernameForNotesEditDialog = userData ? userData.email.split('@')[0] : '';

    // Functions to be passed to Form470ApplicationsColumnsDefinitionCreator for the pencil button onClick:
    const handleEditApplicationNotes = (rowId, fieldName, currentNotes, ben, nickname) => {
        setNotesEditRowId(rowId);
        setNotesFieldName(fieldName);
        setNotesEditDialogTitle(
            `Edit Note for BEN${ben.length > 1 ? 's' : ''}: ${ben}, Application: ${rowId}, Nickname: ${nickname}`
        );
        setNotesEditDialogLabel('Application Notes');
        setEditableNotes(currentNotes ? currentNotes : '');
        setOpenNotesEditDialog(true);
    };
    const handleEditQAReviewerNotes = (rowId, fieldName, currentNotes, ben, nickname) => {
        setNotesEditRowId(rowId);
        setNotesFieldName(fieldName);
        setNotesEditDialogTitle(
            `Edit Note for BEN${ben.length > 1 ? 's' : ''}: ${ben}, Application: ${rowId}, Nickname: ${nickname}`
        );
        setNotesEditDialogLabel('QA Reviewer Notes');
        setEditableNotes(currentNotes ? currentNotes : '');
        setOpenNotesEditDialog(true);
    };

    const handleNotesEditDialogSave = (newNotes) => {
        //console.log('[handleNotesEditDialogSave] ');
        saveNewNotes(newNotes);
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const saveNewNotes = async (newNotes) => {
        // Determine the field title of the notes that was edited. (And sanity check notesFieldName.)
        let fieldTitle = '';
        if (notesFieldName === 'form470_app_notes') {
            fieldTitle = 'Application Notes';
        } else if (notesFieldName === 'qa_notes') {
            fieldTitle = 'QA Reviewer Notes';
        } else {
            console.error(`An Error Occurred. Invalid notesFieldName: ${notesFieldName}`);
            toast.error(`An Error Occurred. (Invalid notesFieldName.)`);
            return;
        }

        // Find the record to edit/update in `trackerData`
        const specificRow = trackerData.find(
            (row) => `${row.form470AppNum}-${row.form470AppIncrement}` === notesEditRowId
        );
        // console.log('[handleSaveNote][specificRow] =', specificRow);

        const uedata = {
            fieldToChange: notesFieldName,
            newData: newNotes,
        };
        // console.log('[handleSaveNote][uedata] =', uedata);

        const apiResponse = await trackersAPI.SaveForm470TrackerNotes(
            uedata,
            specificRow.form470AppNum,
            specificRow.form470AppIncrement
        );
        console.log('[handleSaveNote][apiResponse] =', apiResponse);

        if (apiResponse) {
            const modifiedOn = apiResponse['last_updated_on'];
            const modifiedBy = apiResponse['last_updated_by'];

            // Update the data locally.
            if (notesFieldName === 'form470_app_notes') {
                let newForm470AppNotes = apiResponse['form470_app_notes'];
                updateTrackerData_WithNewForm470AppNotes(notesEditRowId, newForm470AppNotes, modifiedOn, modifiedBy);
            } else if (notesFieldName === 'qa_notes') {
                let newQANotes = apiResponse['qa_notes'];
                updateTrackerData_WithNewQANotes(notesEditRowId, newQANotes, modifiedOn, modifiedBy);
            }

            toast.success(`Successfully updated the ${fieldTitle}`, {
                autoClose: 3000,
            });
        } else {
            toast.error(`Failed to update the ${fieldTitle}`);
        }
    };

    const updateTrackerData_WithNewForm470AppNotes = (rowId, newNotes, modifiedOn, modifiedBy) => {
        let updatedTrackerData = trackerData.map((row) => {
            if (`${row.form470AppNum}-${row.form470AppIncrement}` === rowId) {
                return {
                    ...row,
                    form470_app_notes: newNotes,
                    last_updated_on: modifiedOn,
                    last_updated_by: modifiedBy,
                };
            }
            return row;
        });
        setTrackerData(updatedTrackerData);
    };

    const updateTrackerData_WithNewQANotes = (rowId, newNotes, modifiedOn, modifiedBy) => {
        let updatedTrackerData = trackerData.map((row) => {
            if (`${row.form470AppNum}-${row.form470AppIncrement}` === rowId) {
                return { ...row, qa_notes: newNotes, last_updated_on: modifiedOn, last_updated_by: modifiedBy };
            }
            return row;
        });
        setTrackerData(updatedTrackerData);
    };

    const handleNotesEditDialogCancel = () => {
        //console.log('[handleNotesEditDialogCancel] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const handleNotesEditDialogClose = () => {
        //console.log('[handleNotesEditDialogClose] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const cleanupNotesEditDialogStateVars = () => {
        setNotesEditRowId(null);
        setNotesFieldName('');
        setNotesEditDialogLabel('');
        setNotesEditDialogTitle('');
        setEditableNotes('');
    };

    // ################################### End Custom Notes #############################################

    //----- processRowUpdate and handleProcessRowUpdateError -----
    const processRowUpdate = React.useCallback(
        async (newRow, oldRow) => {
            //console.log('[processRowUpdate] newRow = ');
            //console.log(newRow);
            //console.log('[processRowUpdate] newRow.qa_submitter_user_id = ' + newRow.qa_submitter_user_id);
            //return Promise.reject(new Error('newRow.ApplicationOwnerUserID = ' + newRow.ApplicationOwnerUserID));  // FOR TESTING. Preventing the save and testing inline edit output.
            //return Promise.reject(new Error('newRow.qa_submitter_user_id = ' + newRow.qa_submitter_user_id));  // FOR TESTING. Preventing the save and testing inline edit output.

            if (newRow.ApplicationOwnerUserID == null) {
                return Promise.reject(new Error('Invalid Application Owner User ID: ' + newRow.ApplicationOwnerUserID));
            }
            if (newRow.task_owner_user_id == null) {
                return Promise.reject(new Error('Invalid Task Owner User ID: ' + newRow.task_owner_user_id));
            }
            if (newRow.erc_app_status_id == null) {
                return Promise.reject(new Error('Invalid ERC App Status ID: ' + newRow.erc_app_status_id));
            }
            if (newRow.qa_review_status_id == null) {
                return Promise.reject(new Error('Invalid QA Review Status ID: ' + newRow.qa_review_status_id));
            }
            if (newRow.qa_reviewer_user_id == null) {
                return Promise.reject(new Error('Invalid QA Reviewer User ID: ' + newRow.qa_reviewer_user_id));
            }
            if (newRow.qa_submitter_user_id == null) {
                return Promise.reject(new Error('Invalid QA Submitter User ID: ' + newRow.qa_submitter_user_id));
            }
            if (newRow.qa_bid_eval_status_id == null) {
                return Promise.reject(new Error('Invalid QA Bid Eval Status ID: ' + newRow.qa_bid_eval_status_id));
            }
            if (newRow.qa_bid_eval_reviewer_user_id == null) {
                return Promise.reject(
                    new Error('Invalid QA Bid Eval Reviewer User ID: ' + newRow.qa_bid_eval_reviewer_user_id)
                );
            }
            if (newRow.qa_bid_eval_submitter_user_id == null) {
                return Promise.reject(
                    new Error('Invalid QA Bid Eval Submitter User ID: ' + newRow.qa_bid_eval_submitter_user_id)
                );
            }

            // Andy's Note: Need to switch value from dropdown to enum name instead of value (removing space). Will clean up in post
            if (newRow.qa_darkfiberselfprovisioned_name === 'Dark Fiber') {
                newRow.qa_darkfiberselfprovisioned_name = 'DarkFiber';
            } else if (newRow.qa_darkfiberselfprovisioned_name === 'Self Provisioned') {
                newRow.qa_darkfiberselfprovisioned_name = 'SelfProvisioned';
            }

            // Needed to handle the 2 day calculation for date submitted/needed
            const oldDateSubmitted = oldRow.qa_date_submitted;
            const newDateSubmitted = newRow.qa_date_submitted;

            const oldDateNeeded = oldRow.qa_date_needed;
            const newDateNeeded = newRow.qa_date_needed;

            if (oldDateSubmitted && newDateSubmitted === '') {
                newRow.qa_date_submitted = '<cleared>';
            }
            if (oldDateNeeded && newDateNeeded === '') {
                newRow.qa_date_needed = '<cleared>';
            }

            // Bid-Eval version
            const oldBidEvalDateSubmitted = oldRow.qa_bid_eval_date_submitted;
            const newBidEvalDateSubmitted = newRow.qa_bid_eval_date_submitted;

            const oldBidEvalDateNeeded = oldRow.qa_bid_eval_date_needed;
            const newBidEvalDateNeeded = newRow.qa_bid_eval_date_needed;

            if (oldBidEvalDateSubmitted && newBidEvalDateSubmitted === '') {
                newRow.qa_bid_eval_date_submitted = '<cleared>';
            }
            if (oldBidEvalDateNeeded && newBidEvalDateNeeded === '') {
                newRow.qa_bid_eval_date_needed = '<cleared>';
            }

            let uedata = {
                form470AppNum: newRow.form470AppNum,
                form470AppIncrement: newRow.form470AppIncrement,

                //app model
                erc_form470_app_status_id: newRow.erc_app_status_id,
                form470_app_notes: newRow.form470_app_notes,
                issue_date: newRow.issue_date,
                application_owner_id: newRow.ApplicationOwnerUserID,

                // task user model
                task_owner_id: newRow.task_owner_user_id,
                task_owners: newRow.task_owners,

                // app dates
                appdates_WTPreBidMtg: newRow.appdates_WTPreBidMtg,
                appdates_QuestionsDeadline: newRow.appdates_QuestionsDeadline,
                appdates_AnswersDeadline: newRow.appdates_AnswersDeadline,
                appdates_AnswersPosted: newRow.appdates_AnswersPosted,
                appdates_BidClosing: newRow.appdates_BidClosing,
                appdates_BidEvalCompVendSel: newRow.appdates_BidEvalCompVendSel,
                appdates_Award: newRow.appdates_Award,
                appdates_EmailSentToVendors: newRow.appdates_EmailSentToVendors,

                // qa model
                qa_review_status_id: newRow.qa_review_status_id,
                qa_reviewer_user_id: newRow.qa_reviewer_user_id,
                qa_submitter_user_id: newRow.qa_submitter_user_id,
                qa_date_submitted: newRow.qa_date_submitted,
                qa_date_needed: newRow.qa_date_needed,
                qa_date_assigned: newRow.qa_date_assigned,
                qa_date_completed: newRow.qa_date_completed,

                // qa bid eval model
                qa_bid_eval_status_id: newRow.qa_bid_eval_status_id,
                qa_bid_eval_reviewer_user_id: newRow.qa_bid_eval_reviewer_user_id,
                qa_bid_eval_submitter_user_id: newRow.qa_bid_eval_submitter_user_id,
                qa_bid_eval_date_submitted: newRow.qa_bid_eval_date_submitted,
                qa_bid_eval_date_needed: newRow.qa_bid_eval_date_needed,
                qa_bid_eval_date_assigned: newRow.qa_bid_eval_date_assigned,
                qa_bid_eval_date_completed: newRow.qa_bid_eval_date_completed,

                qa_notes: newRow.qa_notes,
                qa_darkfiberselfprovisioned_name: newRow.qa_darkfiberselfprovisioned_name,
                //qa_issues: newRow.qa_issues,
                date_sent_to_client_to_certify: newRow.date_sent_to_client_to_certify,
            };

            console.log('[processRowUpdate] uedata = ');
            console.log(uedata);

            //return Promise.reject(new Error('WORK-IN-PROGRESS.'));

            const apiResponse = await trackersAPI.SaveForm470TrackerAppDataInline(
                uedata,
                newRow.form470AppNum,
                newRow.form470AppIncrement
            );

            console.log('[processRowUpdate] apiResponse = ');
            console.log(apiResponse);

            if (apiResponse === false) {
                console.error('Unknown error while saving.');
                return Promise.reject(new Error('Unknown error while saving.'));
            }

            // Return the new row data. It needs to contain form470AppNum and form470AppIncrement for the row id as per getRowId.
            setPruUpdatedRow(apiResponse);
            return apiResponse;
        },
        [trackersAPI]
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        //console.log('[handleProcessRowUpdateError] error.message =', error.message);
        toast.error('Error saving data: ' + error.message);
    }, []);

    //----- getRowId and getRowClassName functions. DataGrid columns array. -----
    const getRowId = (row) => row.form470AppNum + '-' + row.form470AppIncrement;
    const getRowClassName = (params) => 'Form470-' + params.row.usac_app_status_text;

    const originalColumnWidthSetter = prefs.setOriginalSavedViewColumnWidthPrefs;
    const originalColumnOrderSetter = prefs.setOriginalSavedViewColumnOrderPrefs;

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleSaveIconClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelIconClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    // prefs.viewCurrentColumnOrderPrefs
    // [haveEditDropdownOptions, prefs.rowHeightPref, currentColumnWidths, currentColumnOrder] // cco causes you to not be able to move multiple cols

    // Creates the (unsorted) columns array. Use for the datagrid below
    const columnsAndModal = React.useMemo(() => {
        // if (!applicantMSFilterOptions || applicantMSFilterOptions.length === 0) {
        //     return [];
        // }

        return Form470ApplicationsColumnsDefinitionCreator(
            handleDetailsButtonClick,
            handleEditButtonClick,
            handleCancelIconClick,
            handleSaveIconClick,
            handleEditApplicationNotes,
            handleEditQAReviewerNotes,
            applicantMSFilterOptions,
            applicantCohortMSFilterOptions,
            form470AFYStatusesMSFilterOptions,
            serviceTypesMSFilterOptions,
            lastUpdatedByMSFilterOptions,
            billedEntityNameMSFilterOptions,
            usacAppStatusMSFilterOptions,
            applicationOwnerEditOptions,
            taskOwnerEditOptions,
            ercAppStatusEditOptions,
            qaReviewStatusEditOptions,
            qaReviewerEditOptions,
            qaSubmitterEditOptions,
            qaBidEvalStatusEditOptions,
            qaBidEvalReviewerEditOptions,
            qaBidEvalSubmitterEditOptions,
            qaIssuesEditOptions,
            qaDarkFiberEditOptions,
            apiRef,
            prefs.rowHeightPref,
            rowModesModel,
            currentColumnWidths,
            originalColumnWidthSetter,
            originalColumnOrderSetter,
            historyModalOpen,
            setHistoryModalOpen,
            activeHistoryForm,
            setActiveHistoryForm
        );
    }, [
        applicantMSFilterOptions,
        applicantCohortMSFilterOptions,
        form470AFYStatusesMSFilterOptions,
        serviceTypesMSFilterOptions,
        haveEditDropdownOptions,
        lastUpdatedByMSFilterOptions,
        billedEntityNameMSFilterOptions,
        rowModesModel,
        usacAppStatusMSFilterOptions,
        prefs.rowHeightPref,
        prefs.viewCurrentColumnOrderPrefs,
        prefs.viewCurrentColumnWidthPrefs,
        activeHistoryForm,
        historyModalOpen,
    ]);
    const columns = columnsAndModal[0];
    const historyModal = columnsAndModal[1];

    //----- Custom column visibility buttons for TrackerDataGridButtons -----
    // 'Hide/Show QA Columns' button
    const qaColumnNamesArray = [
        'qa_submitter_user_id',
        'qa_review_status_id',
        'qa_date_submitted',
        'qa_date_needed',
        'qa_date_assigned',
        'qa_reviewer_user_id',
        'qa_date_completed',
        'qa_bid_eval_status_id',
        'qa_bid_eval_submitter_user_id',
        'qa_bid_eval_date_submitted',
        'qa_bid_eval_date_needed',
        'qa_bid_eval_reviewer_user_id',
        'qa_bid_eval_date_completed',
        'qa_notes',
        // 'qa_darkfiberselfprovisioned_name',
        // 'qa_issues',
    ];
    const initialHideShowStateOfQAColumnsButton = React.useMemo(
        () => determineInitialHideShowState(qaColumnNamesArray, prefs.hiddenColumnPrefs),
        [prefs.hiddenColumnPrefs]
    );
    const customColumnVisibilityButtonsConfig = [
        {
            showText: 'Show QA Columns',
            hideText: 'Hide QA Columns',
            initialAction: initialHideShowStateOfQAColumnsButton, // 'hide' or 'show'
            columns: qaColumnNamesArray,
        },
    ];

    //----- Display -----
    if (trackerData === undefined || trackerData === false) {
        return (
            <>
                <Typography variant='h3' sx={{ marginTop: '25px' }}>
                    An error occured while trying to complete this search
                </Typography>
                <Typography variant='body2' sx={{ marginLeft: '16px' }}>
                    please contact your system administrators and share your search criteria with them.
                </Typography>
            </>
        );
    }

    if (haveEditDropdownOptions === false) {
        // console.log('haveEditDropdownOptions = ' + haveEditDropdownOptions);
        return null;
    }

    return (
        <>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />
            <TDGPrefsContext.Provider value={prefs}>
                <TrackerDataGrid
                    apiRef={apiRef}
                    appliesTo={appliesTo}
                    columnsArray={columns}
                    currentColumnOrder={currentColumnOrder}
                    currentColumnWidths={currentColumnWidths}
                    currentSavedViews={currentSavedViews}
                    currentUserID={currentUserID}
                    customColumnVisibilityButtonsConfig={customColumnVisibilityButtonsConfig}
                    dataIsLoading={dataIsLoading}
                    defaultFilterPrefs={defaultFilterPrefs}
                    defaultHiddenColumns={defaultHiddenColumns}
                    defaultPinnedColumns={defaultPinnedColumns}
                    defaultSortPrefs={defaultSortPrefs}
                    editingSavedViewName={editingSavedViewName}
                    getRowClassName={getRowClassName}
                    getRowId={getRowId}
                    handleEditSavedViewName={handleEditSavedViewName}
                    handleDeleteSavedView={handleDeleteSavedView}
                    handleProcessRowUpdateError={handleProcessRowUpdateError}
                    handleRowModesModelChange={handleRowModesModelChange}
                    handleSaveView={handleSaveView}
                    onCreateMinibidButtonClick={handleCreateMinibidButtonClick}
                    processRowUpdate={processRowUpdate}
                    reportData={trackerData}
                    resetWatcher={resetWatcher}
                    rowModesModel={rowModesModel}
                    selectionModel={selectionModel}
                    setBulkEditButtonClicked={setBulkEditButtonClicked}
                    setCurrentColumnOrder={setCurrentColumnOrder}
                    setCurrentColumnWidths={setCurrentColumnWidths}
                    setEditingSavedViewName={setEditingSavedViewName}
                    setResetWatcher={setResetWatcher}
                    setSelectionModel={setSelectionModel}
                    setTextFieldValue={setTextFieldValue}
                    showCreateMinibidButton={true}
                    usedCacheOnLoad={usedCacheOnLoad}
                    // customSx={{
                    //     '& .MuiDataGrid-row.Form470-Certified:nth-of-type(2n+1)':
                    //         {
                    //             backgroundColor: 'hsl(103, 47%, 90%)',
                    //         },
                    //     '& .MuiDataGrid-row.Form470-Certified:nth-of-type(2n)':
                    //         {
                    //             backgroundColor: 'hsl(103, 43%, 86%)',
                    //         },
                    // }}
                />
            </TDGPrefsContext.Provider>
            {historyModal}
            <CreateMinibidDialog
                open={openCMDialog}
                setOpen={setOpenCMDialog}
                setResult={setCMResult}
                clientAPI={clientAPI}
                trackersAPI={trackersAPI}
            />
            <Form470AppsBulkEdit
                applicationOwnerEditOptions={applicationOwnerEditOptions}
                bulkEditButtonClicked={bulkEditButtonClicked}
                ercAppStatusEditOptions={ercAppStatusEditOptions}
                pruUpdatedRow={pruUpdatedRow}
                qaBidEvalReviewerEditOptions={qaBidEvalReviewerEditOptions}
                qaBidEvalStatusEditOptions={qaBidEvalStatusEditOptions}
                qaBidEvalSubmitterEditOptions={qaBidEvalSubmitterEditOptions}
                qaDarkFiberEditOptions={qaDarkFiberEditOptions}
                qaReviewerEditOptions={qaReviewerEditOptions}
                qaReviewStatusEditOptions={qaReviewStatusEditOptions}
                qaSubmitterEditOptions={qaSubmitterEditOptions}
                selectionModel={selectionModel}
                setBulkEditButtonClicked={setBulkEditButtonClicked}
                setTrackerData={setTrackerData}
                taskOwnerEditOptions={taskOwnerEditOptions}
                trackerData={trackerData}
                trackersAPI={trackersAPI}
                username={userData}
            />

            <NotesEditDialog
                open={openNotesEditDialog}
                title={notesEditDialogTitle}
                label={notesEditDialogLabel}
                initialNotes={editableNotes}
                username={usernameForNotesEditDialog}
                onSave={handleNotesEditDialogSave}
                onCancel={handleNotesEditDialogCancel}
                onClose={handleNotesEditDialogClose}
            />

            <PromptUpdateOrSaveNewViewDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateView={handleOnlyUpdateView}
                handleOnlySaveAsNewView1={handleOnlySaveAsNewView1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewView2={handleOnlySaveAsNewView2}
            />
            {promptSaveNewView()}
        </>
    );
}

function Form470TrackerAppsSearchBox({
    appliesTo,
    benFromURL,
    clearQueryStrings,
    currentUserID,
    defaultBen,
    fundingYearFromURL,
    usacAppStatusFromURL,
    applicationTypeFromURL,
    handleFieldsInitialized,
    handleSearchButtonClick,
    trackersAPI,
    userAPI,
}) {
    //################################################################################
    const LS_INCREMENT = 1;
    const SS_INCREMENT = 1;
    const F470T_APPS_SEARCH_LS_NAME = 'cedF470TSearchFieldValues' + LS_INCREMENT;
    const F470T_APPS_SEARCH_SS_NAME = 'cedF470TSearchFieldValuesSS' + SS_INCREMENT;
    const [searchFieldValuesInLS, setSearchFieldValuesInLS] = useLocalStorage(F470T_APPS_SEARCH_LS_NAME, '');
    //################################################################################

    //################################### SAVED SEARCHES #############################################
    const [currentSavedSearches, setCurrentSavedSearches] = React.useState([]);
    const [selectedSearchName, setSelectedSearchName] = React.useState('');
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const [editingSearchName, setEditingSearchName] = React.useState('');

    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');
    const [savedSearchObject, setSavedSearchObject] = React.useState({});
    //################################### END SAVED SEARCHES #############################################

    const [defaultUserID, setDefaultUserID] = React.useState(null);
    const auth = useAuthContext();

    const accordionCollapsedText = 'Click to Search Form 470 Applications';
    const accordionExpandedText = 'Search Form 470 Applications';
    const [accordionTitle, setAccordionTitle] = React.useState(accordionCollapsedText);
    const [qaFieldsCount, setQAFieldsCount] = React.useState(0);
    const [isQAAccordionSelected, setIsQAAccordionSelected] = React.useState(false);
    //=====  Search Box Options  =====
    const [fundingYearMSOptions, setFundingYearMSOptions] = React.useState([]);
    const [primaryContactMSOptions, setPrimaryContactMSOptions] = React.useState([]);
    const [secondaryContactMSOptions, setSecondaryContactMSOptions] = React.useState([]);
    const [applicationOwnerMSOptions, setApplicationOwnerMSOptions] = React.useState([]);
    const [applicantMSOptions, setApplicantMSOptions] = React.useState([]);
    const [taskOwnerMSOptions, setTaskOwnerMSOptions] = React.useState([]);
    const [teamsMSOptions, setTeamsMSOptions] = React.useState([]);
    const [stateMSOptions, setStateMSOptions] = React.useState([]);
    const [applicantCohortMSOptions, setApplicantCohortMSOptions] = React.useState([]);
    const [servicesMSOptions, setServicesOptions] = React.useState([]);
    const [ercAppStatusMSOptions, setERCAppStatusMSOptions] = React.useState([]);
    const [qaStatusMSOptions, setQAStatusMSOptions] = React.useState([]);
    const [qaReviewerMSOptions, setQAReviewerMSOptions] = React.useState([]);
    const [qaBidEvalStatusMSOptions, setQABidEvalStatusMSOptions] = React.useState([]);
    const [qaBidEvalReviewerMSOptions, setQABidEvalReviewerMSOptions] = React.useState([]);
    const [qaIssueMSOptions, setQAIssueMSOptions] = React.useState([]);

    //=====  Seach Box State  =====
    const [fundingYears, setFundingYears] = React.useState([]);
    const [categoryOfService, setCategoryOfService] = React.useState('0');
    const [ben, setBen] = React.useState(defaultBen ?? '');
    const [applicationNumber, setApplicationNumber] = React.useState('');
    const [nickname, setNickname] = React.useState('');
    const [primaryContacts, setPrimaryContacts] = React.useState([]);
    const [secondaryContacts, setSecondaryContacts] = React.useState([]);
    const [applicationOwners, setApplicationOwners] = React.useState([]);
    const [taskOwners, setTaskOwners] = React.useState([]);
    const [teams, setTeams] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [applicant, setApplicant] = React.useState([]);
    const [applicantCohorts, setApplicantCohorts] = React.useState([]);
    const [serviceTypes, setServiceTypes] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [isAClient, setIsAClient] = React.useState(true);
    const [applicationType, setApplicationType] = React.useState('All');
    const [usacAppStatuses, setUSACAppStatuses] = React.useState([]);
    const [ercAppStatuses, setERCAppStatuses] = React.useState([]);
    const [qaStatuses, setQAStatuses] = React.useState([]);
    const [qaReviewers, setQAReviewers] = React.useState([]);
    const [qaBidEvalStatuses, setQABidEvalStatuses] = React.useState([]);
    const [qaBidEvalReviewers, setQABidEvalReviewers] = React.useState([]);
    const [qaIssues, setQAIssues] = React.useState([]);
    const [areFieldsInitialized, setAreFieldsInitialized] = React.useState(false);

    //=====  Seach Box State (Deadline)  =====
    // const [deadlineFieldName, setDeadlineFieldName] = React.useState(null);
    // const [deadlineFieldOperator, setDeadlineFieldOperator] = React.useState(null);
    // const [deadlineFieldDays, setDeadlineFieldDays] = React.useState(null);
    // const [deadlineValue, setDeadlineValue] = React.useState(deadlineFieldDays ?? '');
    // const [calculatedDeadlineDate, setCalculatedDeadlineDate] = React.useState('');
    // const [deadlineFieldsCount, setDeadlineFieldsCount] = React.useState(0);
    // const [isDeadlineAccordionSelected, setIsDeadlineAccordionSelected] = React.useState(false);

    //=====  Dropdown and multi-select options  =====
    const categoryOfServiceOptions = [
        { text: 'Any Category', value: '0' },
        { text: 'Only Category 1', value: '1' },
        { text: 'Only Category 2', value: '2' },
        { text: 'Both Category 1 and Category 2', value: '1,2' },
    ];

    const applicationTypeOptions = [
        { text: 'All', value: 'All' },
        { text: 'Regular', value: 'Regular' },
        { text: 'Minibid', value: 'Minibid' },
    ];

    const usacAppStatusOptions = [
        { text: 'Incomplete', value: 1 },
        { text: 'Certified', value: 2 },
        { text: 'Cancelled', value: 4 },
    ];

    const serviceTypeOptions = [
        { text: 'BMIC', value: 'BMIC' },
        { text: 'DTIA', value: 'DTIA' },
        { text: 'IC', value: 'IC' },
        { text: 'IAT', value: 'IAT' },
        { text: 'MIBS', value: 'MIBS' },
    ];

    //=====  setDefaultSearchValues, createSearchParametersObject, and setSearchFieldValues  =====
    const setDefaultSearchValues = (defaultUserID) => {
        setFundingYears([new Date().getMonth() > 5 ? new Date().getFullYear() + 1 : new Date().getFullYear()]);
        setSecondaryContacts([]);
        setBen('');
        setSelectedSearchName('');
        setApplicationNumber('');
        setNickname('');
        setCategoryOfService('0');
        setApplicant([]);
        setApplicationOwners([]);
        setTaskOwners([]);
        setServices([]);
        setIsAClient(true);
        setApplicantCohorts([]);
        setUSACAppStatuses([]);
        setERCAppStatuses([]);
        setQAStatuses([]);
        setQAReviewers([]);
        setQABidEvalStatuses([]);
        setQABidEvalReviewers([]);
        setQAIssues([]);
        setTeams([]);
        setStates([]);
        setApplicationType('All');
        setServiceTypes([]);

        if (defaultUserID != null) {
            setPrimaryContacts([defaultUserID]);
        } else {
            setPrimaryContacts([]);
        }
    };

    // Creates an object of key/value pairs of search field data
    const createSearchParametersObject = () => {
        return {
            fundingYears: fundingYears,
            categoryOfService: categoryOfService,
            ben: ben,
            selectedSearchName: selectedSearchName,
            applicationNumber: applicationNumber,
            nickname: nickname,
            primaryContacts: primaryContacts,
            secondaryContacts: secondaryContacts,
            applicationOwners: applicationOwners,
            taskOwners: taskOwners,
            teams: teams,
            applicant: applicant,
            states: states,
            applicantCohorts: applicantCohorts,
            serviceTypes: serviceTypes,
            isAClient: isAClient,
            services: services,
            applicationType: applicationType,
            usacAppStatuses: usacAppStatuses,
            ercAppStatuses: ercAppStatuses,
            qaStatuses: qaStatuses,
            qaReviewers: qaReviewers,
            qaBidEvalStatuses: qaBidEvalStatuses,
            qaBidEvalReviewers: qaBidEvalReviewers,
            qaIssues: qaIssues,
            // deadlineFieldName: deadlineFieldName,
            // deadlineFieldOperator: deadlineFieldOperator,
            // deadlineFieldDays: deadlineFieldDays,
            // calculatedDeadlineDate: calculatedDeadlineDate
        };
    };

    const setSearchFieldValues = (parsedSP) => {
        // Start with the default values
        setDefaultSearchValues(defaultUserID);

        // console.log('Form470TrackerAppsTable: setSearchFieldValuesFN: parsedSP = ', parsedSP);

        // Fill in search values from local/session storage data
        try {
            if (parsedSP.fundingYears != null && Array.isArray(parsedSP.fundingYears)) {
                setFundingYears(parsedSP.fundingYears);
            }
            if (parsedSP.primaryContacts != null && Array.isArray(parsedSP.primaryContacts)) {
                setPrimaryContacts(parsedSP.primaryContacts);
            }
            if (parsedSP.secondaryContacts != null && Array.isArray(parsedSP.secondaryContacts)) {
                setSecondaryContacts(parsedSP.secondaryContacts);
            }
            if (parsedSP.ben != null) {
                setBen(parsedSP.ben);
            }
            if (parsedSP.selectedSearchName != null) {
                setSelectedSearchName(parsedSP.selectedSearchName);
            }
            if (parsedSP.applicationNumber != null) {
                setApplicationNumber(parsedSP.applicationNumber);
            }
            if (parsedSP.nickname != null) {
                setNickname(parsedSP.nickname);
            }
            if (parsedSP.categoryOfService != null) {
                setCategoryOfService(parsedSP.categoryOfService);
            }
            if (parsedSP.applicant != null && Array.isArray(parsedSP.applicant)) {
                setApplicant(parsedSP.applicant);
            }
            if (parsedSP.applicationOwners != null && Array.isArray(parsedSP.applicationOwners)) {
                setApplicationOwners(parsedSP.applicationOwners);
            }
            if (parsedSP.taskOwners != null && Array.isArray(parsedSP.taskOwners)) {
                setTaskOwners(parsedSP.taskOwners);
            }
            if (parsedSP.services != null && Array.isArray(parsedSP.services)) {
                setServices(parsedSP.services);
            }
            if (parsedSP.isAClient != null && (parsedSP.isAClient === true || parsedSP.isAClient === false)) {
                setIsAClient(parsedSP.isAClient);
            }
            if (parsedSP.applicantCohorts != null && Array.isArray(parsedSP.applicantCohorts)) {
                setApplicantCohorts(parsedSP.applicantCohorts);
            }
            if (parsedSP.usacAppStatuses != null && Array.isArray(parsedSP.usacAppStatuses)) {
                setUSACAppStatuses(parsedSP.usacAppStatuses);
            }
            if (parsedSP.ercAppStatuses != null && Array.isArray(parsedSP.ercAppStatuses)) {
                setERCAppStatuses(parsedSP.ercAppStatuses);
            }
            if (parsedSP.qaStatuses != null && Array.isArray(parsedSP.qaStatuses)) {
                setQAStatuses(parsedSP.qaStatuses);
            }
            if (parsedSP.qaReviewers != null && Array.isArray(parsedSP.qaReviewers)) {
                setQAReviewers(parsedSP.qaReviewers);
            }
            if (parsedSP.qaBidEvalStatuses != null && Array.isArray(parsedSP.qaBidEvalStatuses)) {
                setQABidEvalStatuses(parsedSP.qaBidEvalStatuses);
            }
            if (parsedSP.qaBidEvalReviewers != null && Array.isArray(parsedSP.qaBidEvalReviewers)) {
                setQABidEvalReviewers(parsedSP.qaBidEvalReviewers);
            }
            if (parsedSP.qaIssues != null && Array.isArray(parsedSP.qaIssues)) {
                setQAIssues(parsedSP.qaIssues);
            }
            if (parsedSP.serviceTypes != null && Array.isArray(parsedSP.serviceTypes)) {
                setServiceTypes(parsedSP.serviceTypes);
            }
            if (parsedSP.applicationType != null) {
                setApplicationType(parsedSP.applicationType);
            }
            if (parsedSP.teams != null && Array.isArray(parsedSP.teams)) {
                setTeams(parsedSP.teams);
            }

            if (parsedSP.states != null && Array.isArray(parsedSP.states)) {
                setStates(parsedSP.states);
            }
        } catch (error) {
            console.log('error: ' + error);
            toast.error(error);
            return false;
        }
    };

    //################################################################################
    //=====  Functions to retrieve, save, and clear search field values from/to/in storage  =====
    // SOMEDAY: "Storage" may someday also include a database table.

    const retrieveSearchFieldValuesJSONFromStorage = () => {
        // Check session storage first..
        let ss_json = sessionStorage.getItem(F470T_APPS_SEARCH_SS_NAME);
        if (ss_json) {
            return ss_json;
        }
        // Then local storage..
        if (searchFieldValuesInLS) {
            return searchFieldValuesInLS;
        }
        return null;
    };

    const saveSearchFieldValuesJSONToStorage = (json) => {
        setSearchFieldValuesInLS(json);
        sessionStorage.setItem(F470T_APPS_SEARCH_SS_NAME, json);
    };

    const clearSearchFieldValuesStorage = () => {
        setSearchFieldValuesInLS('');
        sessionStorage.setItem(F470T_APPS_SEARCH_SS_NAME, '');
    };
    //################################################################################

    // Gets all of the user's saved searches
    const getSavedSearches = async (ap) => {
        const searchResults = await userAPI.GetSavedSearches(ap);
        const parsedSearchResults = JSON.parse(searchResults);
        // console.log('Form470TrackerAppsTable: getSavedSearchesFN: parsedSearchResults = ', parsedSearchResults);

        // Defines the base layout for all retrieved searches
        const structuredSearches = parsedSearchResults.map((ea_row) => ({
            search_id: ea_row.id,
            search_name: ea_row.search_name,
            parameters: ea_row,
        }));
        structuredSearches.sort((a, b) => a.search_name.localeCompare(b.search_name));
        // console.log('Form470TrackerAppsTable: getSavedSearchesFN: structuredSearches = ', structuredSearches);
        setCurrentSavedSearches(structuredSearches);
    };

    const setQueryStringStateChanges = () => {
        setBen(benFromURL ? benFromURL : '');
        setFundingYears([fundingYearFromURL].length > 0 ? [fundingYearFromURL] : '');
        // console.log(
        //     'Form470TrackerAppsSearchBox: | !isNaN(usacAppStatusFromURL) = ',
        //     !isNaN(usacAppStatusFromURL),
        //     ' | [usacAppStatusFromURL].length > 0 = ',
        //     [usacAppStatusFromURL].length > 0
        // );
        setUSACAppStatuses(
            !isNaN(usacAppStatusFromURL) && [usacAppStatusFromURL].length > 0 ? [usacAppStatusFromURL] : []
        );
        setApplicationType(applicationTypeFromURL ? applicationTypeFromURL : 'All');
        setIsAClient(false);
    };

    // Populates the multi-select options
    React.useEffect(() => {
        const getSearchOptionsAndInitialize = async () => {
            try {
                // Get dropdown and multi-select options
                // Returns a data-populated object of drop-down options, then sets the corresponding states with it
                let options = await trackersAPI.GetForm470TrackerMainReportSearchOptions();

                setFundingYearMSOptions(options.fundingYears);
                setPrimaryContactMSOptions(options.employees);
                setSecondaryContactMSOptions(options.employees);
                setApplicantMSOptions(options.applicants); // (client_name, client_id).
                setApplicationOwnerMSOptions(options.employees);
                setTaskOwnerMSOptions(options.employees);
                setApplicantCohortMSOptions(options.applicantCohorts);
                setERCAppStatusMSOptions(options.ercAppStatuses);
                setQAStatusMSOptions(options.qaReviewStatuses);
                setQAReviewerMSOptions(options.employees);
                setQABidEvalStatusMSOptions(options.qaBidEvalStatuses);
                setQABidEvalReviewerMSOptions(options.employees);
                setQAIssueMSOptions(options.qaIssues);
                setTeamsMSOptions(options.teams);
                setStateMSOptions(options.states);
                setServicesOptions(options.form470Services);

                // Get the user's userId
                let userID = currentUserID;

                // Check if that user (id) in the "Primary Contacts" options array..
                let pcoption = options.employees.find((pc) => pc.value === userID);
                if (!pcoption) {
                    userID = null;
                }

                setDefaultUserID(userID);

                // Initialize search box to previous values or defaults
                // console.log(
                //     'Form470TrackerAppsSearchBox: benFromURL = ',
                //     benFromURL,
                //     ' | fundingYearFromURL = ',
                //     fundingYearFromURL,
                //     ' | usacAppStatusFromURL = ',
                //     usacAppStatusFromURL, ' | applicationTypeFromURL = ', applicationTypeFromURL
                // );
                let sfv_json = retrieveSearchFieldValuesJSONFromStorage();
                if (sfv_json && (!benFromURL || !fundingYearFromURL)) {
                    setSearchFieldValues(JSON.parse(sfv_json));
                } else if (!sfv_json && (!benFromURL || !fundingYearFromURL)) {
                    setDefaultSearchValues(userID);
                } else {
                    setQueryStringStateChanges();
                }

                setAreFieldsInitialized(true);
            } catch (error) {
                console.log('Error getting search options: ' + error);
                toast.error(error);
            }
        };

        getSearchOptionsAndInitialize();
        getSavedSearches(appliesTo);
    }, []);

    // Execute handleFieldsInitialized after the search fields have been initialized.
    React.useEffect(() => {
        if (areFieldsInitialized) {
            handleFieldsInitialized(createSearchParametersObject());
        }
    }, [areFieldsInitialized]);

    //=====  Dropdown and multi-select change handlers  =====
    const handleFundingYearChange = (event, selectedValues) => {
        setFundingYears(selectedValues);
    };

    const handlePrimaryContactChange = (event, selectedValues) => {
        setPrimaryContacts(selectedValues);
    };
    const handleSecondaryContactChange = (event, selectedValues) => {
        setSecondaryContacts(selectedValues);
    };

    const handleApplicationOwnerChange = (event, selectedValues) => {
        setApplicationOwners(selectedValues);
    };

    const handleTaskOwnersChange = (event, selectedValues) => {
        setTaskOwners(selectedValues);
    };

    const handleTeamsChange = (event, selectedValues) => {
        setTeams(selectedValues);
    };

    const handleApplicantChange = (event, selectedValues) => {
        setApplicant(selectedValues);
    };

    const handleStatesChange = (event, selectedValues) => {
        setStates(selectedValues);
    };

    const handleApplicantCohortChange = (event, selectedValues) => {
        setApplicantCohorts(selectedValues);
    };

    const handleServicesChange = (event, selectedValues) => {
        setServices(selectedValues);
    };

    const handleUSACAppStatusChange = (event, selectedValues) => {
        setUSACAppStatuses(selectedValues);
    };

    const handleERCAppStatusChange = (event, selectedValues) => {
        setERCAppStatuses(selectedValues);
    };

    const handleQAStatusChange = (event, selectedValues) => {
        setQAStatuses(selectedValues);
    };

    // const handleDeadlineFieldStatusChange = (event) => {
    //     setDeadlineFieldName(event);
    // };

    // const handleDeadlineOperatorStatusChange = (event) => {
    //     setDeadlineFieldOperator(event);
    // };

    // const handleDeadlineDaysChange = (event) => {
    //     setDeadlineFieldDays(event);
    // };

    const handleQAReviewerChange = (event, selectedValues) => {
        setQAReviewers(selectedValues);
    };

    const handleQABidEvalStatusChange = (event, selectedValues) => {
        setQABidEvalStatuses(selectedValues);
    };

    const handleQABidEvalReviewerChange = (event, selectedValues) => {
        setQABidEvalReviewers(selectedValues);
    };

    const handleQAIssuesChange = (event, selectedValues) => {
        setQAIssues(selectedValues);
    };

    const handleServiceTypesChange = (event, selectedValues) => {
        setServiceTypes(selectedValues);
    };

    React.useEffect(() => {
        const totals =
            qaStatuses.length +
            qaReviewers.length +
            qaIssues.length +
            qaBidEvalStatuses.length +
            qaBidEvalReviewers.length;

        const anySelected = totals > 0;

        if (anySelected !== isQAAccordionSelected) {
            setIsQAAccordionSelected(anySelected);
        }

        if (anySelected) {
            setQAFieldsCount(totals);
        } else if (qaFieldsCount !== 0) {
            setQAFieldsCount(0);
        }
    }, [
        qaStatuses,
        qaReviewers,
        qaIssues,
        qaBidEvalStatuses,
        qaBidEvalReviewers,
        qaFieldsCount,
        isQAAccordionSelected,
    ]);

    // For Deadlines
    // React.useEffect(() => {
    //     const totals = (deadlineFieldName ? 1 : 0) + (deadlineFieldOperator ? 1 : 0) + (deadlineValue ? 1 : 0);

    //     const anySelected = totals > 0;

    //     if (anySelected !== isDeadlineAccordionSelected) {
    //         setIsDeadlineAccordionSelected(anySelected);
    //     }

    //     if (anySelected) {
    //         setDeadlineFieldsCount(totals);
    //     } else if (deadlineFieldsCount !== 0) {
    //         setDeadlineFieldsCount(0);
    //     }
    // }, [deadlineFieldName, deadlineFieldOperator, deadlineValue, deadlineFieldsCount, isDeadlineAccordionSelected]);
    //################################### SAVED SEARCHES #############################################
    const endsWithSearch = (searchName) => {
        const lowercaseName = searchName.toLowerCase();
        return lowercaseName.endsWith('search') || lowercaseName.split(-6).pop().endsWith('search');
    };

    // Check if the search name already exists
    const searchNameExists = (searchName) => {
        return currentSavedSearches.some((ea_search) => ea_search.search_name === searchName);
    };

    // Components rendered when the user begins to edit a MenuItem
    const EditableMenuItem = ({ ea_search, onSave, onCancel }) => {
        const [editValue, setEditValue] = React.useState(ea_search.search_name);

        const handleKeyDown = (event) => {
            // Stops the select component from navigating while typing
            event.stopPropagation();
            if (event.key === 'Enter') {
                onSave(ea_search.search_name, editValue);
            }
        };

        return (
            <MenuItem>
                <TextField
                    value={editValue}
                    onChange={(event) => setEditValue(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={() => onSave(ea_search.search_name, editValue)}>
                    <DoneIcon fontSize='inherit' />
                </IconButton>
                <IconButton onClick={onCancel}>
                    <CancelIcon fontSize='inherit' />
                </IconButton>
            </MenuItem>
        );
    };

    const handleSaveSearch = async (sfv_obj) => {
        setSavedSearchObject(sfv_obj);

        if (selectedSearchName !== '' && selectedSearchName !== null) {
            // Search for duplicate/determine if the user has already selected a search
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === selectedSearchName
            );

            // If a duplicate was found/the user has already selected a view
            if (duplicateSearchIndex !== -1) {
                openDialog1(selectedSearchName); // opens the promptUpdateOrSaveNewView
            }
        } else {
            openDialog2();
            promptSaveNewSearch();
        }
    };

    const handleOnlyUpdateSearch = async () => {
        let message = 'update';
        let newOrUpdatedSearchName = selectedSearchName;
        let tempCurrentSavedSearches = [...currentSavedSearches];

        const duplicateSearchIndex = currentSavedSearches.findIndex(
            (ea_search) => ea_search.search_name === newOrUpdatedSearchName
        );

        // Finds the matching search object
        const matchingSavedSearch = currentSavedSearches[duplicateSearchIndex];

        // Building the updated search object (state updates too slowly to add searchName to CSPO)
        const replacementSearch = {
            search_id: matchingSavedSearch.parameters.id,
            search_name: newOrUpdatedSearchName,
            parameters: savedSearchObject,
        };

        // Update state
        tempCurrentSavedSearches[duplicateSearchIndex] = replacementSearch;
        const response = await userAPI.SaveSearch(
            currentUserID,
            message,
            appliesTo,
            tempCurrentSavedSearches.at(duplicateSearchIndex)
        );

        if (response) {
            setLanding(false);
            setShowTextField1(false);
            setIsDialogOpen1(false);

            let successMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Successfully updated the ${newOrUpdatedSearchName}`
                : `Successfully updated the ${newOrUpdatedSearchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Failed to update the ${newOrUpdatedSearchName}`
                : `Failed to update the ${newOrUpdatedSearchName} saved search`;

            toast.error(failedMessage);
        }
        getSavedSearches(appliesTo);
        setLanding(true);
    };

    const handleOnlySaveAsNewSearch1 = () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewSearch2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved search name cannot be empty!');
            return;
        }

        const message = 'new';
        const searchName = textFieldValue.trim();
        const tempCurrentSavedSearches = [...currentSavedSearches];

        if (searchNameExists(searchName)) {
            toast.error(
                `A saved search with the name ${searchName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const newSearch = {
            search_name: searchName,
            parameters: savedSearchObject,
        };
        newSearch['parameters']['selectedSearchName'] = searchName;
        tempCurrentSavedSearches.push(newSearch);

        const response = await userAPI.SaveSearch(currentUserID, message, appliesTo, tempCurrentSavedSearches.at(-1));
        if (response) {
            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            let successMessage = endsWithSearch(searchName)
                ? `Successfully created the ${searchName}`
                : `Successfully created the ${searchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(searchName)
                ? `Failed to create the ${searchName}`
                : `Failed to create the ${searchName} saved search`;

            toast.error(failedMessage);
        }
        setLanding(true);
        getSavedSearches(appliesTo);
        setSelectedSearchName(searchName);
    };

    // Called when the user selects a search from the dropdown
    const handleSelectSavedSearch = (search_name) => {
        setSelectedSearchName(search_name);

        // Finds the matching search object
        const retrieved_search = currentSavedSearches.find((ea_search) => ea_search.search_name === search_name);
        if (retrieved_search) {
            setSearchFieldValues(retrieved_search.parameters.search_filters); // parses the values into searchbar
        } else {
            return;
        }
    };

    // Called when the user clicks the edit icon
    const handleEditSavedSearchName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/search_name already exists for the other saved searches
            if (currentSavedSearches.some((ea_search) => ea_search.search_name === new_name)) {
                alert('A saved search with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the search we want to edit
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === old_name
            );

            // If a duplicate was found
            if (duplicateSearchIndex !== -1) {
                // Returns an array of objects with the previous saved search + the edited search
                const updatedSearches = currentSavedSearches.map((ea_search, index) => {
                    if (index === duplicateSearchIndex) {
                        return {
                            ...ea_search,
                            search_name: new_name,
                            parameters: {
                                ...ea_search.parameters,
                                search_name: new_name,
                                search_filters: {
                                    ...ea_search.parameters.search_filters,
                                    selectedSearchName: new_name,
                                },
                            },
                        };
                    }
                    return ea_search;
                });
                // console.log(
                //     'Form470TrackerAppsTable: editSavedSearchNameFN: modified/edited object = ',
                //     updatedSearches.at(duplicateSearchIndex)
                // );

                // Update the state
                if (selectedSearchName === old_name) {
                    setSelectedSearchName(new_name);
                }
                setCurrentSavedSearches(updatedSearches);

                // Process the edited search
                const search_response = await userAPI.SaveSearch(
                    currentUserID,
                    message,
                    appliesTo,
                    updatedSearches.at(duplicateSearchIndex)
                );

                if (search_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }
                getSavedSearches(appliesTo);
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedSearch = async (search_name, search_id, applies_to, user_id) => {
        let confirmationMessage = endsWithSearch(search_name)
            ? `Are you sure you want to delete the ${search_name}?`
            : `Are you sure you want to delete the ${search_name} saved search?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedSearch(search_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithSearch(search_name)
                    ? `Successfully deleted the ${search_name}`
                    : `Successfully deleted the ${search_name} saved search`;

                toast.success(successMessage, {
                    autoClose: 3000,
                });

                if (search_name === selectedSearchName) {
                    setSelectedSearchName('');
                    resetToDefaults();
                }
                getSavedSearches(appliesTo);
            } else {
                const errorMessage = endsWithSearch(search_name)
                    ? `Failed to delete the ${search_name}`
                    : `Failed to delete the ${search_name} saved search`;

                toast.error(errorMessage);
            }
            // console.log('Form470TrackerAppsTable: deleteSavedSearchFN: delete_response = ', delete_response);
        }
    };

    const searchButtonClicked = () => {
        clearQueryStrings();
        if (isNaN(ben) || isNaN(applicationNumber)) {
            if (isNaN(ben)) {
                toast.error('BEN must be a number');
            }

            if (isNaN(applicationNumber)) {
                toast.error('Application Number must be a number');
            }
        } else {
            let sfv_obj = createSearchParametersObject();
            saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
            handleSearchButtonClick(sfv_obj);
        }
    };

    const saveSearchButtonClicked = () => {
        let sfv_obj = createSearchParametersObject();
        handleSaveSearch(sfv_obj);
        saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewSearch2();
        }
    };

    const openDialog1 = (searchName) => {
        let confirmationMessage = endsWithSearch(searchName)
            ? `Do you want to update the ${searchName} or save as a new search?`
            : `Do you want to update the ${searchName} saved search or save as a new search?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewSearch = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved search: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='Search Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewSearch2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    //################################### END SAVED SEARCHES #############################################

    // const deadlineFieldsMapping = [
    //     { text: 'W/T/PreBid Mtg Date', value: 'appdates_WTPreBidMtg' },
    //     { text: 'Questions Deadline Date', value: 'appdates_QuestionsDeadline' },
    //     { text: 'Answers Deadline Date', value: 'appdates_AnswersDeadline' },
    //     { text: 'Answers Posted Date', value: 'appdates_AnswersPosted' },
    //     { text: 'Bid Closing Date', value: 'appdates_BidClosing' },
    //     { text: 'Bid Evaluation Complete/Vendor Selected Date', value: 'appdates_BidEvalCompVendSel' },
    //     { text: 'Award Date', value: 'appdates_Award' },
    //     { text: 'Date Email Sent to Vendors', value: 'appdates_EmailSentToVendors' },
    //     { text: 'Date Sent to Client to Certify', value: 'date_sent_to_client_to_certify' },
    //     { text: 'Issue Date', value: 'issue_date' },
    //     { text: 'Bid Eval QA Date Completed', value: 'qa_bid_eval_date_completed' },
    //     { text: 'Bid Eval QA Date Needed', value: 'qa_bid_eval_date_needed' },
    //     { text: 'Bid Eval QA Date Submitted', value: 'qa_bid_eval_date_submitted' },
    //     { text: '470 QA Date Assigned', value: 'qa_date_assigned' },
    //     { text: '470 QA Date Completed', value: 'qa_date_completed' },
    //     { text: '470 QA Date Needed', value: 'qa_date_needed' },
    //     { text: '470 QA Date Submitted', value: 'qa_date_submitted' },
    // ];
    // deadlineFieldsMapping.sort((a, b) => a.text.localeCompare(b.text));

    // const deadlineFieldsOperatorMapping = [
    //     { text: 'is x days past deadline', value: 'isBeforeXDays' },
    //     { text: 'is x days out from deadline', value: 'isAfterXDays' },
    // ];

    // const deadlineDaysOptions = [
    //     { text: '30', value: 30 },
    //     { text: '60', value: 60 },
    //     { text: '90', value: 90 },
    // ];

    // const handleInputChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (inputValue === '') {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     } else {
    //         setDeadlineValue(inputValue);
    //         handleDeadlineDaysChange(inputValue);
    //     }
    // };

    // const handleSelect = (event, newValue) => {
    //     if (newValue !== null) {
    //         setDeadlineValue(newValue.value);
    //         handleDeadlineDaysChange(newValue.value);
    //     } else {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     }
    // };

    //=====  Accordion and button handlers  =====
    const handleAccordionOnChange = (event, expanded) => {
        setAccordionTitle(expanded ? accordionExpandedText : accordionCollapsedText);
    };

    const resetToDefaults = () => {
        clearSearchFieldValuesStorage();
        setTextFieldValue('');
        setDefaultSearchValues(defaultUserID);
    };

    return (
        <>
            <Accordion sx={{ marginBottom: '1em' }} onChange={handleAccordionOnChange}>
                <AccordionSummary
                    id='search-panel-header'
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='search-panel-content'
                    sx={{ m: 0 }}
                >
                    {accordionTitle}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            {/* FUNDING YEAR */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-funding-year'
                                    selectedOptions={fundingYears}
                                    options={fundingYearMSOptions}
                                    handleSelectChange={handleFundingYearChange}
                                    label='Funding Year'
                                />
                            </FormControl>

                            {/* CATEGORY OF SERVICE */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <InputLabel id='search-category-of-service-label'>Category of Service</InputLabel>
                                <Select
                                    labelId='search-category-of-service-label'
                                    id='search-category-of-service'
                                    value={categoryOfService}
                                    label='Category of Service'
                                    onChange={(event) => setCategoryOfService(event.target.value)}
                                    displayEmpty
                                >
                                    {categoryOfServiceOptions.map((row, i) => {
                                        return (
                                            <MenuItem value={row.value} key={row.value}>
                                                {row.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            {/* BEN */}
                            <TextField
                                onChange={(event) => setBen(event.target.value)}
                                value={ben}
                                label='BEN'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />

                            {/* APP NUM */}
                            <TextField
                                onChange={(event) => setApplicationNumber(event.target.value)}
                                value={applicationNumber}
                                label='Form 470 Application Number'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />

                            {/* NICKNAME */}
                            <TextField
                                onChange={(event) => setNickname(event.target.value)}
                                value={nickname}
                                label='Nickname'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />

                            {/* APPLICATION TYPE */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <InputLabel id='search-application-type-label'>Application Type</InputLabel>
                                <Select
                                    labelId='search-application-type-label'
                                    id='search-application-type'
                                    value={applicationType}
                                    label='Applkication Type'
                                    onChange={(event) => setApplicationType(event.target.value)}
                                    displayEmpty
                                >
                                    {applicationTypeOptions.map((row, i) => {
                                        return (
                                            <MenuItem value={row.value} key={row.value}>
                                                {row.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* PRIMARY CONTACT */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-primary-contact'
                                    selectedOptions={primaryContacts}
                                    options={primaryContactMSOptions}
                                    handleSelectChange={handlePrimaryContactChange}
                                    label='Primary Contact'
                                />
                            </FormControl>

                            {/* SECONDARY CONTACT */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-secondary-contact'
                                    selectedOptions={secondaryContacts}
                                    options={secondaryContactMSOptions}
                                    handleSelectChange={handleSecondaryContactChange}
                                    label='Secondary Contact'
                                />
                            </FormControl>

                            {/* APPLICATION OWNER */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-application-owner'
                                    selectedOptions={applicationOwners}
                                    options={applicationOwnerMSOptions}
                                    handleSelectChange={handleApplicationOwnerChange}
                                    label='Application Owner'
                                />
                            </FormControl>

                            {/* TASK OWNER */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-task-owner'
                                    selectedOptions={taskOwners}
                                    options={taskOwnerMSOptions}
                                    handleSelectChange={handleTaskOwnersChange}
                                    label='Task Owner'
                                />
                            </FormControl>

                            {/* TEAMS */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-teams'
                                    selectedOptions={teams}
                                    options={teamsMSOptions}
                                    handleSelectChange={handleTeamsChange}
                                    label='Teams'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* APPLICANTS */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicant'
                                    selectedOptions={applicant}
                                    options={applicantMSOptions}
                                    handleSelectChange={handleApplicantChange}
                                    label='Applicants'
                                />
                            </FormControl>

                            {/* CLIENT STATE */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-states'
                                    selectedOptions={states}
                                    options={stateMSOptions}
                                    handleSelectChange={handleStatesChange}
                                    label='Client State'
                                />
                            </FormControl>

                            {/* APPLICANT COHORTS */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicantcohort'
                                    selectedOptions={applicantCohorts}
                                    options={applicantCohortMSOptions}
                                    handleSelectChange={handleApplicantCohortChange}
                                    label='Applicant Cohorts'
                                />
                            </FormControl>

                            {/* SERVICES */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-services'
                                    selectedOptions={services}
                                    options={servicesMSOptions}
                                    handleSelectChange={handleServicesChange}
                                    label='Services'
                                />
                            </FormControl>

                            {/* IS A CLIENT */}
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAClient}
                                            onChange={(event) => setIsAClient(event.target.checked)}
                                        />
                                    }
                                    label='Is A Client'
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* USAC APP STATUS */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-usac-app-status'
                                    selectedOptions={usacAppStatuses}
                                    options={usacAppStatusOptions}
                                    handleSelectChange={handleUSACAppStatusChange}
                                    label='USAC App Status'
                                />
                            </FormControl>

                            {/* ERC APP STATUS */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-erc-app-status'
                                    selectedOptions={ercAppStatuses}
                                    options={ercAppStatusMSOptions}
                                    handleSelectChange={handleERCAppStatusChange}
                                    label='ERC App Status'
                                />
                            </FormControl>

                            {/* SERVICE TYPES */}
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-service-types'
                                    selectedOptions={serviceTypes}
                                    options={serviceTypeOptions}
                                    handleSelectChange={handleServiceTypesChange}
                                    label='Service Types'
                                />
                            </FormControl>

                            <Accordion
                                style={{
                                    border: isQAAccordionSelected ? '2px solid #4CAF50' : '',
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='qa-fields'
                                    id='qa-fields'
                                    sx={{ mt: 1, mb: 0.7 }}
                                >
                                    <Typography color='textSecondary'>
                                        {isQAAccordionSelected
                                            ? `(${qaFieldsCount}) QA Field${qaFieldsCount > 1 ? 's' : ''} Selected`
                                            : 'QA Fields'}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* QA STATUS */}
                                    <FormControl fullWidth margin='dense' size='small'>
                                        <MultiAutoComplete
                                            id='search-qa-status'
                                            selectedOptions={qaStatuses}
                                            options={qaStatusMSOptions}
                                            handleSelectChange={handleQAStatusChange}
                                            label='470 QA Status'
                                        />
                                    </FormControl>

                                    {/* QA REVIEWER */}
                                    <FormControl fullWidth margin='dense' size='small'>
                                        <MultiAutoComplete
                                            id='search-qa-reviewer'
                                            selectedOptions={qaReviewers}
                                            options={qaReviewerMSOptions}
                                            handleSelectChange={handleQAReviewerChange}
                                            label='470 QA Reviewer'
                                        />
                                    </FormControl>

                                    <FormControl fullWidth margin='dense' size='small'>
                                        <MultiAutoComplete
                                            id='qa-issues'
                                            selectedOptions={qaIssues}
                                            options={qaIssueMSOptions}
                                            handleSelectChange={handleQAIssuesChange}
                                            label='QA Issues'
                                        />
                                    </FormControl>

                                    {/* QA BID EVAL STATUS */}
                                    <FormControl fullWidth margin='dense' size='small'>
                                        <MultiAutoComplete
                                            id='search-qa-bid-eval-status'
                                            selectedOptions={qaBidEvalStatuses}
                                            options={qaBidEvalStatusMSOptions}
                                            handleSelectChange={handleQABidEvalStatusChange}
                                            label='Bid Eval Status'
                                        />
                                    </FormControl>

                                    {/* QA BID EVAL REVIEWER */}
                                    <FormControl fullWidth margin='dense' size='small'>
                                        <MultiAutoComplete
                                            id='search-qa-bid-eval-reviewer'
                                            selectedOptions={qaBidEvalReviewers}
                                            options={qaBidEvalReviewerMSOptions}
                                            handleSelectChange={handleQABidEvalReviewerChange}
                                            label='Bid Eval Reviewer'
                                        />
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>

                            {/* Deadline Dropdowns */}
                            {/* <Accordion
                                style={{
                                    border: isDeadlineAccordionSelected ? '2px solid #4CAF50' : '',
                                }}
                            > */}
                            {/* <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='qa-fields'
                                    id='qa-fields'
                                    sx={{ mt: 1, mb: 0.7 }}
                                >
                                    <Typography color='textSecondary'>
                                        {isDeadlineAccordionSelected
                                            ? `(${deadlineFieldsCount}) Deadline Field${
                                                  deadlineFieldsCount > 1 ? 's' : ''
                                              } Selected`
                                            : 'Deadline Fields'}
                                    </Typography>
                                </AccordionSummary> */}
                            {/* <AccordionDetails> */}
                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            id='deadline-field-options'
                                            options={deadlineFieldsMapping}
                                            getOptionLabel={(option) => (option.text ? option.text : '')}
                                            value={deadlineFieldName}
                                            isOptionEqualToValue={(option, value) => {
                                                // console.log(option, value);
                                                return option.value === value.value;
                                            }}
                                            onChange={(event, newValue) => {
                                                handleDeadlineFieldStatusChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label='Date Fields' variant='outlined' />
                                            )}
                                        />
                                    </FormControl> */}

                            {/* Before or After Deadline */}
                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            id='deadline-field-operator-options'
                                            options={deadlineFieldsOperatorMapping}
                                            getOptionLabel={(option) => (option.text ? option.text : '')}
                                            value={deadlineFieldOperator}
                                            isOptionEqualToValue={(option, value) => {
                                                // console.log(option, value);
                                                return option.value === value.value;
                                            }}
                                            onChange={(event, newValue) => {
                                                handleDeadlineOperatorStatusChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label='Operators' variant='outlined' />
                                            )}
                                        />
                                    </FormControl> */}

                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            freeSolo
                                            options={deadlineDaysOptions}
                                            getOptionLabel={(option) => option.text || ''}
                                            value={
                                                deadlineDaysOptions.find((option) => option.value === deadlineValue) ||
                                                null
                                            }
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            onChange={handleSelect}
                                            onInputChange={(event, value) => {
                                                // Handles the direct text input from renderInput
                                                if (value === '') {
                                                    setDeadlineValue('');
                                                    handleDeadlineDaysChange('');
                                                } else {
                                                    setDeadlineValue(value);
                                                    handleDeadlineDaysChange(value);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    id='amount-of-days'
                                                    label='Amount of Days'
                                                    type='number'
                                                    value={deadlineValue}
                                                    onChange={handleInputChange}
                                                    variant='outlined'
                                                />
                                            )}
                                        />
                                    </FormControl> */}
                            {/* </AccordionDetails> */}
                            {/* </Accordion> */}
                        </Grid>
                    </Grid>

                    <Box display='flex' justifyContent='space-between' width='100%'>
                        <Box flex='1' maxWidth='340.2px' mr={0}>
                            <FormControl fullWidth={true} margin='normal'>
                                <InputLabel
                                    id='ercAppStatus-label'
                                    shrink={true}
                                    sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                                >
                                    Saved Searches
                                </InputLabel>
                                <Select
                                    id='currentSavedSearches-select'
                                    labelId='currentSavedSearches-label'
                                    label='Current Saved Searches'
                                    value={selectedSearchName ?? ''}
                                    renderValue={(selected) => (selected ? selected : 'Select a search')}
                                    onChange={(event) => {
                                        handleSelectSavedSearch(event.target.value);
                                    }}
                                    onOpen={() => setIsSelectOpen(true)}
                                    onClose={() => setIsSelectOpen(false)}
                                >
                                    <MenuItem value='' onClick={resetToDefaults}>
                                        Default
                                    </MenuItem>
                                    {currentSavedSearches.map((ea_search, index) => {
                                        if (editingSearchName === ea_search.search_name) {
                                            return (
                                                <EditableMenuItem
                                                    key={index}
                                                    ea_search={ea_search}
                                                    onSave={(oldName, newName) => {
                                                        handleEditSavedSearchName(oldName, newName);
                                                        setEditingSearchName(null);
                                                    }}
                                                    onCancel={() => {
                                                        setEditingSearchName(null);
                                                    }}
                                                />
                                            );
                                        } else {
                                            return (
                                                <MenuItem key={index} value={ea_search.search_name}>
                                                    {ea_search.search_name}
                                                    {isSelectOpen && (
                                                        <>
                                                            <Tooltip title='Edit Name'>
                                                                <IconButton
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setEditingSearchName(ea_search.search_name);
                                                                    }}
                                                                    size='small'
                                                                    style={{ marginLeft: '8px' }}
                                                                >
                                                                    <EditIcon fontSize='inherit' />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title='Delete'>
                                                                <IconButton
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleDeleteSavedSearch(
                                                                            ea_search.search_name,
                                                                            ea_search.search_id,
                                                                            appliesTo,
                                                                            currentUserID
                                                                        );
                                                                    }}
                                                                    size='small'
                                                                    style={{ marginLeft: '8px' }}
                                                                >
                                                                    <DeleteIcon fontSize='inherit' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </Box>

                        <Grid
                            item
                            xs={12}
                            md={7}
                            mr={4}
                            container
                            justifyContent='flex-start'
                            spacing={2}
                            marginTop={'1em'}
                        >
                            <Grid item>
                                <Button
                                    variant='contained'
                                    onClick={searchButtonClicked}
                                    sx={{ backgroundColor: '#4CAF50' }}
                                >
                                    Search
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' onClick={saveSearchButtonClicked}>
                                    {selectedSearchName ? 'Update Search' : 'Save Search'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='secondary' onClick={resetToDefaults}>
                                    Reset to Defaults
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <PromptUpdateOrSaveNewSearchDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateSearch={handleOnlyUpdateSearch}
                handleOnlySaveAsNewSearch1={handleOnlySaveAsNewSearch1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewSearch2={handleOnlySaveAsNewSearch2}
            />
            {promptSaveNewSearch()}
        </>
    );
}

function LastForm470UsacDataRetrievalDatetimes({ trackersAPI }) {
    // STUB
    const [epcForm470Datetime, setEPCForm470Datetime] = React.useState(null);
    const [odForm470Datetime, setODForm470Datetime] = React.useState(null);

    React.useEffect(() => {
        const getDatetimes = async () => {
            try {
                let data = await trackersAPI.GetLastUsacDataRetrievalDatetimes('Form470TrackerMetadata');
                setEPCForm470Datetime(data.epcForm470_datetime);
                setODForm470Datetime(data.odForm470_datetime);
            } catch (error) {
                console.error('error: ', error);
                toast.error(error);
            }
        };

        getDatetimes();
    }, []);

    const renderDateTime = (isoDateTimeString, displayTime) => {
        if (isoDateTimeString) {
            try {
                let datedate = new Date(isoDateTimeString);
                let text;
                if (!displayTime) {
                    text = new Intl.DateTimeFormat('en-US').format(datedate);
                } else {
                    let dtfoptions = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    };
                    text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate);
                }
                return <span title={datedate}>{text}</span>;
            } catch (err) {
                console.error('renderDateTime error:', err);
            }
        }
        return isoDateTimeString;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', fontSize: '0.8em', marginY: '0.8em' }}>
            <Box>
                <Link
                    href={`https://centraled.sharepoint.com/:f:/r/sites/E-RateCentral/Shared%20Documents/Best%20Practices/Form%20470?csf=1&web=1&e=76Epsu`}
                    target='_blank'
                    rel='noreferrer'
                    sx={{
                        fontWeight: 'normal',
                        fontSize: '.75rem',
                    }}
                >
                    {'470 Best Practices'}
                </Link>
            </Box>
            {epcForm470Datetime && (
                <Box sx={{ marginLeft: 2 }}>
                    Application data EPC last scraped: {renderDateTime(epcForm470Datetime, true)}
                </Box>
            )}
            {odForm470Datetime && (
                <Box sx={{ marginLeft: 2 }}>
                    Application data from Open Data Form 470 dataset: {renderDateTime(odForm470Datetime, true)}
                </Box>
            )}
            <Box sx={{ marginLeft: 2 }}>
                <Link
                    href={'/usacdataretrievaldatetimes'}
                    target='_blank'
                    rel='noopener'
                    sx={{
                        fontWeight: 'normal',
                        fontSize: '.75rem',
                    }}
                >
                    Form 470 Data Retrieval Datetimes
                </Link>
            </Box>
        </Box>
    );
}
